<template>
  <endpoint-sec />
</template>

<script>
import EndpointSec from "../../components/solutions/security-solutions/EndpointSec.vue";
export default {
  components: { EndpointSec },
  name: "endpoint-security-page",
};
</script>
